import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import GoodInventoryImageSource from 'images/featured-image/good-inventory.jpg';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import OptinBlog from 'components/pages/OptinBlog';
import { PostsWrapper } from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, BottomShare, SectionFull, OptinSection} from 'components/blog/SingleBlogStyle';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query ImgBlog4Query {
      file(relativePath: { eq: "good-inventory-banner.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="two man inside the warehouse" />;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        title={intl.formatMessage({ id: "blog-post-meta-title-benefits-of-an-inventory" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "How can your business benefit from a good Inventory management system? Find out how it can scale up your eCommerce business.",
            },
            {
              property: `og:title`,
              // content: "Benefits of a Good Inventory Management System | Shipkoo",
              ontent: intl.formatMessage({ id: "blog-post-meta-title-benefits-of-an-inventory" }),
            },
            {
              property: `og:description`,
              content: "How can your business benefit from a good Inventory management system? Find out how it can scale up your eCommerce business.",
            },
            {
              property: `og:image`,
              content: GoodInventoryImageSource,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="benefits-of-an-inventory-management-system"
        title="Benefits of a Good Inventory Management System"
        date="2020-03-13"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              Managing inventory is important when running an eCommerce business. It helps you gain visibility across your entire supply chain. It is important in enhancing product quality, improving competitive ability, reducing inventory carrying costs by reducing inventories, service enhancement, and operational flexibility. 
            </p>
            
            <p>
              The more detailed your stock records are, the easier it is for you to fulfill orders and make business decisions based on what's selling.
            </p>

            <p>
              No need to use  ledgers or log books. The good news is that everything is done online. Everything is automated!
            </p>

            <h5>Why Does Inventory Management Matter?</h5>
            
            <p>
              <span>
                <strong>
                  1. Maximizes sales and minimizes expenses
                </strong>  
              </span>
            </p>

            <p>
              Having inventory management helps you respond to your customer demands immediately while making the sales flow smoothly. While having poor inventory management can cause significant revenue losses, driven by avoidable errors including stock-outs and overstocks.
            </p>

            <p>
              <span>
                <strong>
                  2. Manual processes and tasks automation
                </strong>  
              </span>
            </p>
            
            <p>
              Nobody wants doing manual data entry tasks or complex manual calculations with every purchase order. It’s best to leave it to a software that can automate these common tasks for you.
            </p>

            <p>
              An automated inventory management system removes the burden of having to list everything out which could lead possibly to some errors that can damage your business.
            </p>

            <p>
              <span>
                <strong>
                  3. Saves time 
                </strong>  
              </span>
            </p>

            <p>
              Through the help of an inventory management system, you can always keep track of the products you have on-hand or ordered, saving you from your effort of doing an inventory recount. A good inventory management strategy also helps you save time allowing you to focus on other more important  areas of your business
            </p>

            <Image src={GoodInventoryImageSource} alt="benefits of good inventory management system" />

            <h5>What’s in it for you?</h5>

            <p>
              It will be difficult to manage your business without a competent and reliable inventory management system. It would be your decision to choose which one works for you and your business.
            </p>

            <p>
              Invest in an automated inventory management system that is compatible and easy to integrate into your stores.  
            </p>
            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="FacebookDefaultIcon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="TwitterDefaultIcon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="LinkedinDefaultIcon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;

